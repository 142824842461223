<template>
  <v-container fluid class="ma-0 pa-0">
    <v-autocomplete :value="selectedVendors" :items="vendors" item-text="name" item-value="vendor_id" label="Vendors"
      :loading="searching" :search-input.sync="vendorSearch" :menu-props="{closeOnContentClick:true}" background-color="input"
      :placeholder="isAdmin ? 'Start typing to search' : null" hide-no-data hide-details multiple cache-items
      return-object small-chips deletable-chips dense outlined @change="$emit('update:selectedVendors', $event)">
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item v-bind="attrs" color="primary" v-on="on">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.party_type_name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-container>
</template>
<script>
// API
import Search from '@/axios/search-endpoint'
// mixins
import { userAccess } from '@/mixins/user-access'
import { displayAlert } from '@/mixins/alert'
// third-party
import { debounce } from 'lodash'
export default {
  data() {
    return {
      vendors: [],
      vendorSearch: '',
      searching: false
    }
  },
  name: 'VendorSelect',
  mixins: [userAccess, displayAlert],
  props: {
    selectedVendors: Array
  },
  watch: {
    vendorSearch: {
      handler: debounce(function (newValue) {
        if (this.searchEnabled && newValue) {
          this.searchVendors()
        }
      }, 500)
    },
    selectedVendors: {
      handler(newValue) {
        this.vendorSearch = ''
        if (newValue && newValue.length > 0) {
          this.initVendors()
        } else {
          this.vendors = []
        }
      },
      deep: true
    },
    userRelatedParties: {
      handler(newValue) {
        if (newValue && newValue.length > 0 && this.limitAccessByRelatedVendors) {
          this.vendors.push(...this.userDropdownParties)
        }
      },
      deep: true
    }
  },
  created() {
    if (this.selectedVendors.length > 0) {
      this.initVendors()
    }
    if (this.limitAccessByRelatedVendors && this.userRelatedParties.length > 0) {
      this.vendors.push(...this.userDropdownParties)
    }
  },
  computed: {
    userDropdownParties() {
      return this.userVendors.flatMap(vendor => this.formatParty(vendor))
    },
    searchEnabled() {
      return !this.limitAccessByRelatedVendors
    }
  },
  methods: {
    initVendors() {
      this.vendors = [...this.selectedVendors]
    },
    async searchVendors() {
      this.searching = true
      const term = this.vendorSearch || ''
      let vendors = []
      try {
        const res = await Search.customerSearch(term, 'and')
        if (res && res.data && res.data.length > 0) {
          vendors = res.data.flatMap(party => {
            return (party.party_type) === 'VENDOR'
              ? this.formatParty(party)
              : []
          })
        }
        this.vendors = [...this.vendors, ...vendors]
      } catch (err) {
        this.handleError(err)
      } finally {
        this.searching = false
      }
    },
    formatParty(party) {
      let display_name = party.party_name || party.name
      const partyType = party.party_type_constant || party.party_type
      if (party.attributes) {
        const attrVal = party.attributes.VENDOR_ID || ''
        display_name = `${attrVal} ${display_name}`
      }
      party.name = display_name
      party.party_type_name = this.formatPartyType(partyType)
      if (!party.id) party.id = party.party_id
      return party
    },
    formatPartyType(str) {
      str = str.toLowerCase().replaceAll('_', ' ')
      const parts = str.split(' ').map(part => part.charAt(0).toUpperCase() + part.slice(1))
      return parts.join(' ')
    }
  }
}
</script>